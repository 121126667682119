import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck as faCircleCheckSolid, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

const DropItem = ({ draggableId, index, title, handleComplete }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="bg-ash-lighter flex rounded items-center transition-transform"
        > 
          <div className="p-4" {...provided.dragHandleProps}><FontAwesomeIcon icon={faGripLines} className="opacity-20"/></div>
          <p className="flex-auto text-left">{title}</p>
          <button 
            onClick={handleComplete} 
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="flex-none p-4">
            <FontAwesomeIcon icon={hovered ? faCircleCheckSolid : faCircleCheck} color="#00a67d" />
          </button>
        </div>
      )}
    </Draggable>
  );
};

export default DropItem;
