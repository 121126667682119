import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { storeData, retrieveData } from '../utils/redisClient';
import DropColumn from './DropColumn';
import DropItem from './DropItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import CompletedItem from './CompletedItem';
import ToggleSwitch from './ToggleSwitch';

const openCardsDataKey = 'priority-list-data';
const completedCardsDataKey = 'priority-list-data-completed';

const DropArea = () => {
  const [cards, setCards] = useState([]);
  const [completedCards, setCompletedCards] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isCompletedToggleOn, setIsCompletedToggleOn] = useState(false);

  const handleCompletedToggleChanged = () => {
    setIsCompletedToggleOn(!isCompletedToggleOn);
  };

  const updateCards = (list) => {
    setCards(list);
    handleStoreOpenData(list);
  }

  const handleAddCard = () => {
    if (inputValue.trim()) {
      const newList = [...cards, inputValue];
      console.log(newList);
      updateCards(newList);
      setInputValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddCard();
    }
  };

  const handleCompleteCard = (index) => {
    const completedCard = cards[index];
    updateCards(cards.filter((_, i) => i !== index));
    
    const list = [...completedCards, { title: completedCard, completed: Date.now() }];
    setCompletedCards(list);
    handleStoreCompletedData(list);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(cards);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCards(items);
  };

  const handleStoreOpenData = async (list) => {
    await storeData(openCardsDataKey, list);
  };

  const handleRetrieveOpenData = async () => {
    const cardData = await retrieveData(openCardsDataKey);
    setCards(cardData || []);
  };

  const handleStoreCompletedData = async (list) => {
    await storeData(completedCardsDataKey, list);
  };

  const handleRetrieveCompletedData = async () => {
    const cardData = await retrieveData(completedCardsDataKey);
    setCompletedCards(cardData || []);
  };

  useEffect(() => {
    handleRetrieveOpenData();
    handleRetrieveCompletedData();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-8 mb-16 items-center">
        <div className="flex flex-col gap-8 w-full">     
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <DropColumn droppableId="droppable-cards">
              { 
                cards.length > 0 ? (
                  cards.map((card, index) => (
                    <DropItem key={index} draggableId={`card-${index}`} index={index} title={card} handleComplete={() => handleCompleteCard(index)} />
                  ))
                ) : (
                  <p className='italic'>Put your feet up. You've got nothing to do!</p>
                )
              }
            </DropColumn>
          </DragDropContext>
        </div>
        <ToggleSwitch label="Show Completed" isOn={isCompletedToggleOn} handleToggle={handleCompletedToggleChanged} />
        
        <div className={`flex flex-col gap-8 w-full ${ isCompletedToggleOn ? '' : 'hidden' }`}>
          <div className="p-4 bg-ash-light rounded flex flex-col gap-4">
            { 
              completedCards.map((card, index) => (
                <CompletedItem index={`completed-${index}`} title={card.title} completedDate={card.completed} />
              ))
            }
          </div>
        </div>


      </div>
      <div className='fixed bottom-0 left-0 right-0 bg-ash-light flex items-center p-4 gap'>
        <input 
          type="text" 
          value={inputValue} 
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 p-4 rounded-l bg-ash text-white border-white focus-visible:outline-none"/>
        <button 
          onClick={handleAddCard} 
          className="py-4 px-8 bg-ash-lighter rounded-r">
            <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
    </>
  );
};

export default DropArea;
