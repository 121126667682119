import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

const DropColumn = ({ droppableId, children }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="p-4 bg-ash-light rounded flex flex-col gap-4">
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DropColumn;
