import { getFakeData } from "./fakeDataGenerator";

const redisBaseUrl = process.env.REACT_APP_UPSTASH_REDIS_URL;
const redisToken = process.env.REACT_APP_UPSTASH_REDIS_TOKEN;

/**
 * Stores JSON data in Upstash Redis via REST API.
 * @param {string} key - The key under which the data will be stored.
 * @param {Object} data - The data to store (will be stringified).
 * @returns {Promise<void>}
 */
export const storeData = async (key, data) => {
  if (process.env.REACT_APP_UPSTASH_USE_SIMULATED_RESPONSES === true) {
    return;
  }

  // Convert the data to a JSON string before storing
  const jsonString = JSON.stringify(data);

  // Prepare the Redis SET command as an array
  const redisCommand = ["set", key, jsonString];

  const response = await fetch(`${redisBaseUrl}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${redisToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(redisCommand), // Send the command as a JSON string
  });

  if (!response.ok) {
    throw new Error('Failed to store data');
  }

  console.log(`Data stored under key "${key}":`, data);
};

/**
 * Retrieves JSON data from Upstash Redis via REST API.
 * @param {string} key - The key under which the data is stored.
 * @returns {Promise<Object|null>} The parsed JSON data, or null if not found.
 */
export const retrieveData = async (key) => {
  if (process.env.REACT_APP_UPSTASH_USE_SIMULATED_RESPONSES === true) {
    return getFakeData(20);
  }

  // Prepare the Redis GET command as an array
  const redisCommand = ["get", key];

  const response = await fetch(`${redisBaseUrl}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${redisToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(redisCommand), // Send the command as a JSON string
  });

  if (!response.ok) {
    if (response.status === 404) {
      console.log(`No data found for key "${key}"`);
      return null;
    }
    throw new Error('Failed to retrieve data');
  }

  const data = await response.json();
  console.log(`Data retrieved from key "${key}":`, data.result);

  // Parse the result from JSON string back to its original form
  return data.result ? JSON.parse(data.result) : null;
};