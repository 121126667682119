import React from 'react';

function ToggleSwitch({ label, isOn, handleToggle }) {
  return (
    <div className="flex items-center">
        <span className="mr-3 text-ash text-sm">{label}</span>
        <label className="relative inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                className="sr-only"
                checked={isOn}
                onChange={handleToggle}
            />
            <div
                className={`w-11 h-7 pt-1 rounded-full transition-colors ${
                    isOn ? 'bg-ash-green' : 'bg-ash-lighter'
                }`}
            >
                <div
                    className={`w-5 h-5 bg-ash rounded-full shadow-md transform transition-transform ${
                    isOn ? 'translate-x-5' : 'translate-x-1'
                    }`}
                />
            </div>
        </label>
    </div>
  );
}

export default ToggleSwitch;
