import DropArea from './components/DropArea';

function App() {
  return (
    <div className="App bg-ash p-12">
      <header className="App-header flex items-center flex-col text-white min-h-screen">
        
        <DropArea />
      </header>
    </div>
  );
}

export default App;