import { format } from 'date-fns';

const CompletedItem = ({ index, title, completedDate }) => {
  const formattedCompletedDate = format(new Date(completedDate), "MMM dd, hh:mma");

  return (
    <div 
      key={index}
      className="bg-ash-lighter flex flex-col text-left rounded transition-transform p-4"
    >
      <p>{title}</p>
      <p className="mt-2 text-ash uppercase text-xs font-bold">Completed {formattedCompletedDate}</p>
    </div>
  );
};

export default CompletedItem;
