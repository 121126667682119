import { faker } from '@faker-js/faker';

export const getFakeData = (count) => {
    let fakes = [];

    for (let i = 0; i < count; i++) {
        fakes.push(faker.hacker.phrase());
    }

    return fakes;
}